.Footer {
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--noir);
    font-size: 13px;
    line-height: 1.6em;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
}

.Footer p {
    color: var(--gris);
}

#copyright {
    margin-left: 5rem;
}

.ElyLogo {
    width: 7rem;
    margin-right: 5rem;
}

@media screen and (max-width: 600px) {
    .Footer {
        justify-content: center;
        flex-direction: column;
    }

    p {
        margin-left: 0rem;
    }

    .ElyLogo {
        margin-right: 0rem;
    }
}

/* @media screen and (max-width: 800px) {
    .Footer {
        position: fixed;
    }
} */