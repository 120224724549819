.Header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--blanc);
}

.ElyoteLogo {
    width: 15rem;
    margin: 1rem;
}