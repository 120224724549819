/* TODO : A DECOMMENTER POUR MISE EN PROD */
:root {
  --violet: #8554C5;
  --noir: #2e2e2e;
  --gris: #E7E7E7;
  --blanc: #FFFFFF;
  --ecriture: rgb(50, 50, 50);
  --bleu: rgb(101, 168, 255);
  --vert: #84DB8D;
  --fondCopieur:#FFFFFF;
  --valeurs: rgb(116, 115, 115);
  --rouge: #ED4F4F;
}

/* TODO : A SUPPRIMER POUR LA MISE EN PROD */
/* Dark mode - DEV MODE */
/* :root {
  --violet: #8554C5;
  --noir: #2e2e2e;
  --gris: #2e2e2e;
  --blanc: #FFFFFF;
  --ecriture: rgb(232, 232, 232);
  --bleu: rgb(101, 168, 255);
  --vert: #84DB8D;
  --fondCopieur:#454545;
  --valeurs: rgb(116, 115, 115);
  --rouge: #ED4F4F;
} */

html {
  background-color: var(--gris);
  font-family: 'Poppins', sans-serif ;
}
@media screen and (min-width: 500px) {

.CopieurList {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
}

.PhraseContainer {
/*display: none;*/
}

/*.PhraseContainer.active {*/
  .PhraseContainer { 
display: flex;
justify-content: space-between;
align-items: center;
/*color: var(--gris);*/
color: var(--noir);
margin-top: 1rem;
padding-top: 1rem;
padding-left: 3rem;
padding-right: 3rem;
padding-bottom: 1rem;
/*border: 2px solid var(--rouge);*/
border-radius: 5px;
/*background-color: var(--rouge);*/
background-color:#fff;
}

.PhraseDisconnect {
color: var(--gris);
margin-top: 1rem;
margin-bottom: 1rem;
padding-top: 1rem;
padding-left: 3rem;
padding-right: 3rem;
padding-bottom: 1rem;
border: 2px solid var(--rouge);
border-radius: 5px;
background-color: var(--rouge);

}
.PhraseDisconnect a {
  color:#fff;  
}
  

.fermerInfo {
background-color: var(--violet);
border: transparent;
border-radius: 0.5rem;
color: var(--gris);
padding-left: 1rem;
padding-right: 1rem;
padding-top: 0.5rem;
padding-bottom: 0.5rem;
font-size: 0.9rem;
cursor: pointer;
margin-top: .5rem;
}

/*.PhraseContainer.detail {*/
  .detail{
  display: block;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--noir);
  margin-top: 1rem;
  padding-top: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 1rem;
  border-radius: 5px;
  background-color: #ffffff;
  border: 2px solid #8554c5;
}
  

.PhraseContainer.histo {
display: block;
text-align: center;
margin: 1rem;
}

.PhraseContainer.histo table {
margin-left: 10%;
width: 80%;
border-collapse: collapse;
margin-bottom: 1rem;;
background-color: #FFFFFF;
color: #2e2e2e;
}

.PhraseContainer.histo table tr th {
border: 1px solid var(--noir);
padding: 1rem;
}

.PhraseContainer.histo table tbody tr td {
border: 1px solid var(--noir);
}

@media screen and (max-width: 500px) {

/*
.CopieurList {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 240vw;
  transform: rotate(90deg) translateY(-100vh);
  transform-origin: top left;
  

}

.CopieurContainer {
  width: 100vh;
  height: 100vw;
 transform: rotate(-90deg) translateX(-100vh);
  transform-origin: top left;
  overflow-x: hidden;
}

*/  

.CopieurList {
  display: flex;
  overflow-x: scroll;
  padding: 1rem 0;
  scroll-snap-type: x mandatory;
}

.CopieurItem {
  flex: 1 0 150px;
  margin-left: 1rem;
  scroll-snap-align: center;
  flex-basis: 200px;

}

::-webkit-scrollbar-track {
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
    height: 6px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    background-color: #3d4852;
    border-radius: 3px;
}

.Admin {
  flex-direction: column!important;
  margin-top: 5rem;
}
}

.erreurCopieur{
  margin-bottom: .5rem;
}
/*message disconnect true */
.PhraseDisconnect {
  color: var(--gris);
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-top: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 1rem;
  border: 2px solid var(--rouge);
  border-radius: 5px;
  background-color: var(--rouge);
 /*display: none;*/
  
  }

  .errorNotification{
    border: 2px solid var(--violet);
    background-color: var(--violet);
  }

  .shownMessage{
    display: block,
  }
  
