.startCircle {
    font-family: 'Poppins', sans-serif;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    background-color: var(--vert);
    border: solid transparent;
    border-radius: 3rem;
}

.startCircle:hover {
    border: 2px solid black;
    cursor: pointer;
}

.startButton {
    color: #fff;
    font-size: 2rem;
}

.stop {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    background-color: var(--rouge);
    border: solid transparent;
    border-radius: 3rem;
}

.stop:hover {
    border: 2px solid black;
    cursor: pointer;
}

.desactiveCircle {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    background-color: var(--gris);
    border: solid transparent;
    border-radius: 3rem;
}

/*.historique {
    font-size: 0.5rem;
}

.historique:hover {
    font-size: 0.5rem;
    cursor: pointer;
}
*/
/*.details {
    color: var(--noir);
    background-color: transparent;
    border: transparent;
    font-family: 'Poppins', sans-serif;
    font-size: 0.5rem;*/
   /* margin-left: 9.3rem;*/
/*}*/

/*.details:hover {
    text-decoration: underline;
    color: var(--bleu);
    cursor: pointer;
}*/



.loading {
    display: none;
}

.loading.active {
    display: block;
}

.infoBox {
   display: flex;
    justify-content: space-between;
    margin-bottom: 1rem ;
    width: 100%;
}
.divOccupe{
    
display: flex;
align-items: center;
justify-content: center;
margin-bottom: 2rem;
padding : 0.5rem;
color:var(--blanc);
text-transform: uppercase;
border-radius: 5%;
font-size: 1.5rem;
font-family: Poppins, sans-serif;
    
}
.backr{
    background-color: var(--rouge);
}

.backv{
    background-color: var(--vert);
}

.backg{
    background-color: var(--gris);
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }