
.CopieurItem {
    font-family: 'Poppins', sans-serif;
    align-items: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    filter : drop-shadow(0px 6.37365px 23.5335px rgba(0, 0, 0, 0.15));;
    width: 17.5rem;
    margin: 3rem;
   background-color: var(--fondCopieur);
    border-radius: 15px;
}


.copieurName {
    color: var(--ecriture);
    font-size: 1.5rem;
    margin-bottom: 1rem ;
}


.historique {
    color: var(--noir);
    background-color: transparent;
    border: transparent;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: normal;
}

.historique:hover {
    color: var(--bleu);
    text-decoration: underline;
}

.copieurInformation {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 1rem ;
}

.copieurInformation > button {
    color: var(--blanc);
    border-radius: 0.4rem;
    border-style: none;
    margin: 0.25rem;
    padding: 0.5rem;
    width: 10rem;
    
}

.copieurInformation > button:first-child {
    background-image: -webkit-linear-gradient(90deg, #8554C5 50%, transparent 50%);
    background-image: linear-gradient(90deg, #8554C5 50%, transparent 50%);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 500ms ease-in-out;
    transition: background 500ms ease-in-out;
}

.copieurInformation > button:hover {
    cursor: pointer;
    background-position: 100%;
    color: #8554C5;
}

.nbStat {
    display: flex;
    flex-direction: row;
}

.nbStatType {
    font-size: 1rem;
}

.nbColonne {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    color: var(--ecriture);
}

.nbColonne > h1 {
    font-size: 1.5rem;
}

.square {
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--violet);
    min-width: 4rem;
    padding: 0px 2px 0px 2px;
    margin: -0.5rem;
    margin-bottom: 0.5rem;
    height: 4rem;
    line-height: 0.01rem;
    border-radius: 20%;
}

.squareDesactivated {
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--gris);
    width: 4rem;
    margin: -0.5rem;
    margin-bottom: 0.5rem;
    height: 4rem;
    line-height: 0.01rem;
    border-radius: 20%;
}

.sizeSmallNumber{
    font-size: 2em;
}
.sizeBigNumber{
    font-size: 1.3em;
}

.timeZone{
    display: flex;
    align-items: center;
    justify-content: center;
   /* flex-direction: row;*/
   flex-direction: column;
    color: var(--ecriture);
    border: 2px solid transparent;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    font-size: 0.7rem;
    margin-top: 2rem;
}

.timeZone > h2{
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    margin-top: 0;
}

/* code de start.css migré dans copyItem.Css */
.historique {
    font-size: 0.6rem;
}

.historique:hover {
    font-size: 0.5rem;
    cursor: pointer;
}

.details {
    color: var(--noir);
    background-color: transparent;
    border: transparent;
    font-family: 'Poppins', sans-serif;
    font-size: 0.6rem;
    margin-left: 9.3rem;
}

.details:hover {
    text-decoration: underline;
    color: var(--bleu);
    cursor: pointer;
}

/*.infoBox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem ;
   
}*/

