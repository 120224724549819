.Admin {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    /* margin-top: 5rem; */
    margin-bottom: 10rem;
    
}

.BAdmin {
    display: flex;            /* new */
     align-items: center;      /* new */
     justify-content: center;
     text-align: center; 
    background-color: var(--violet);
    color: var(--blanc);
    width: 7.5rem;
    height: 3rem;
    margin: 1rem;
    border-width: 0rem;
    border-radius: 0.4rem;
    text-decoration: none;
    padding: 0.5rem
    
}

.BAdmin:hover {
    background-color: var(--bleu);
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .Admin {
        margin-bottom: 0rem;
    }
}